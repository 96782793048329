<template>
	<div>
		<modal :show.sync="showFilterModal" body-classes="p-0"
			modal-classes="modal-dialog-centered modal-md show d-block"
		>
			<div class="modal-header">
				<h5 class="modal-title">Venue Search Filter</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					@click="showFilterModal = false"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
                <div class="row">
                    <div class="col mb-4 text-danger">
                        The event enquiry is missing some important information. Please update the following details to continue the search.
                    </div>
                </div>
                <div class="row mb-3" v-if="locationIsInvalid">
					<div class="col">
						<h3>Event City</h3>
						<div>
							<el-select v-model="selectedLocation" placeholder="Select">
								<el-option v-for="(city, index) in selectableLocations" :key="`location_${city.name}_${index}`"
									:label="city.name"
									:value="city.id"
								></el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="row mb-4" v-if="guestCountIsInvalid">
					<div class="col">
						<h3>Estimated No. of Guests</h3>
						<div class="block">
                            <el-input v-model="estimatedGuestCount" placeholder="Guest count"
                                type="number" min="1" max="1000"
                            ></el-input>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer justify-content-between">
				<div class="col pr-0 text-right">
					<button type="button" class="btn btn-primary" :disabled="! formIsValid" @click="submitForm">Save</button>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import { Option, Select, Slider } from "element-ui";
import recommendationMixin from '@/helpers/recommendationMixin';

export default {
	props: {
		showFilter: {
			type: Boolean,
			default: false
		},
		selectableLocations: {
			type: Array,
			default() { return []; }
		},
        locationIsInvalid: {
            type: Boolean,
            default: false
        },
        guestCountIsInvalid: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal,
        [Option.name]: Option,
        [Select.name]: Select,
        [Slider.name]: Slider,
    },
    data() {
        return {
            showFilterModal: false,
            selectedLocation: null,
            estimatedGuestCount: null
        };
    },
    computed: {
        formIsValid() {
            if (! this.locationIsInvalid && ! this.guestCountIsInvalid) {
                return true;
            }

            if (! this.locationIsInvalid && this.estimatedGuestCount > 0) {
                return true;
            }

            if (! this.guestCountIsInvalid && this.selectedLocation > 0) {
                return true;
            }

            return false;
        }
    },
    watch: {
        showFilter: function (newVal) {
            this.showFilterModal = newVal;
        },
        showFilterModal: function (newVal) {
            if (! newVal) {
                this.$emit('update:showFilter', false);
            }
        }
    },
    mixins: [recommendationMixin],
    methods: {
        async submitForm() {

            let params = {};

            if (this.selectedLocation ) {
                params['location'] = this.selectedLocation;
            }

            if (this.estimatedGuestCount ) {
                params['guests'] = this.estimatedGuestCount;
            }

            let results = await this.searchVenues(
                this.$route.params.id,
                this.$route.params.eventId,
                {},
                params
            );

            if (results.data) {
                this.updateKarenListingPageData(results.data);
            }

            this.showFilterModal = false;
        }
    }
};
</script>

<style lang="scss">
@mixin form-label {
	color: #000;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.modal {
	.modal-content {
		border-radius: 20px;
        .modal-body {
            .el-slider {
                .el-slider__runway {
                    border-radius: 50px;
                }
            }
        }
    }
}
</style>
