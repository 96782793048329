<template>
	<div class="col-12 col-sm-6 col-md-4">
		<div class="card venue-listing">
			<div class="card-header">
				<div class="col card-header-content" @click="roomSidebar(venue)">
					<div class="row">
						<div class="col-9">
							<span class="venue-location">
								<i class="fal fa-map-marker"></i>
                                {{ venue.location_string }} ({{ venue.distance }} {{ currentCountry.measurementUnit }})
							</span>
						</div>
						<div class="col-3 pr-0">
							<div :class="{
								'venue-rating low': venue.percentile_score >= 0 && venue.percentile_score <= 33.3,
								'venue-rating med': venue.percentile_score > 33.3 && venue.percentile_score <= 66.66,
								'venue-rating high': venue.percentile_score > 66.66 && venue.percentile_score <= 100,
                                'relative': venue.is_high_conversion_venue
							}">
								{{ venue.percentile_score }}
                                <i v-if="venue.is_high_conversion_venue" class="high-converting fas fa-star"></i>
                                <span v-if="venue.is_high_conversion_venue" class="icon-tooltip">High booking rate</span>
							</div>
						</div>
					</div>
					<div class="row venue-title">
						<div class="pl-0 d-flex flex-grow-1">
							<h6 class="card-subtitle mb-2 text-muted">{{ venue.room_name }}</h6>
							<h5 class="card-title">{{ venue.venue_name }}</h5>
						</div>
                        <div class="bg-info rounded-start rounded-left font-weight-bold text-dark text-right px-3" v-if="venue.has_been_recommended_already">
                            Recommendation sent
                        </div>
					</div>
				</div>
				<div class="image-header-overlay"></div>
				<div class="image-header" :style="{ 'background-image': 'url(' + venue.image_url + ')' }"></div>
			</div>
			<div class="card-body" :class="{ 'already-recommended': venue.has_been_recommended_already }">
				<div class="card-body-content">
					<div class="row">
						<div class="col-8 text-left">
							<div class="row venue-detail">
                                <div class="col">
                                    <i class="fal fa-plate-utensils fa-venue-detail"></i>
                                    {{ venue.dinner_and_dance_min_capacity }} - {{ venue.dinner_and_dance_max_capacity }}
                                </div>
                                <div class="col">
                                    <i class="fal fa-martini-glass-citrus fa-venue-detail"></i>
                                    {{ venue.standing_min_capacity }} - {{ venue.standing_max_capacity }}
                                </div>
							</div>
                            <div class="row mt-2 venue-detail">
                                <div class="col">
                                    <i class="fal fa-male fa-venue-detail"></i>
                                    {{ currentCountry.currencySymbol }}{{ venue.cost_per_person }}/PP
                                </div>
                                <div class="col">
                                    <i class="fal fa-usd-circle fa-venue-detail"></i>
                                    {{ currentCountry.currencySymbol }}{{ venue.min_cost_for_event }}+
                                </div>
                            </div>
						</div>
                        <div class="col-4 text-right">
							<div class="row venue-detail">
                                <div class="col pl-0">
                                    <i class="fal fa-cutlery fa-venue-detail"></i>
                                    {{ venue.external_catering_permitted ? 'YES' : 'NO' }}
                                </div>
							</div>
						</div>
					</div>
					<div class="row card-usps">
						<div class="col">
                            {{ venue.room_description }}
						</div>
					</div>
				</div>
				<div class="row card-actions">
					<button :class="['col pl-0 text-center action-accept', { 'active': selected }]"
						:disabled="venue.has_been_recommended_already" @click="addRoomToRecommendedList">
						<i class="fa fa-check fa-action-item"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Notification } from 'element-ui';

export default {
	props: {
		venue: {
			type: Object,
			required: true
		},
		currentCountry: {
			type: Object,
			required: true
		},
		recommendations: {
			type: Array
		}
	},
	computed: {
		formattedCountry() {
			return this.currentCountry;
		},
		miniumCost() {
			return function (venue) {
				return venue.cost_per_person * venue.min_capacity == 0
					? venue.min_capacity + ' per person'
					: venue.cost_per_person * venue.min_capacity;
			};
		},
		selected() {
			const index = this.recommendations.indexOf(this.venue);
			return index !== -1;
		}
	},
	methods: {
		addRoomToRecommendedList() {
			if (!this.selected && this.recommendations.length == 6) {
				Notification.error({
					title: 'Recommendation Limit Reached',
					message: 'You have reached the maximum limit of 6 recommended venues/rooms. Please review your selections.',
					showClose: true
				});
				return;
			}

			let savedRecommendations = this.recommendations;
			if (!this.selected) {
				savedRecommendations.push(this.venue);
				this.$emit('update:recommendations', savedRecommendations);
			} else {
				const index = savedRecommendations.indexOf(this.venue);
				if (index > -1) {
					savedRecommendations.splice(index, 1);
					this.$emit('update:recommendations', savedRecommendations);
				}
			}
		},
		roomSidebar(venue) {
			this.$emit('toggle-drawer', venue);
		}
	}
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom/_karenv2card";
</style>