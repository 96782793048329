import { HTTP } from '../services';
import buildQuery from '@/helpers/url';

export default new class KarenService {
	async getSettings(country) {
	 
		let params = buildQuery({country});

		return await HTTP.get('/v1/karen/settings?' + params);
	};

	async saveSettings(country, settingsWeight, secondarySettingsActive) {
		let params = {
			country,
			settings_weight: settingsWeight,
			secondary_settings_active: secondarySettingsActive,
		};

		return await HTTP.post('/v1/karen/settings', params);
	};

	async loadKarenFilterData(country, event_type, seating_type) {
		let params = {
			country,
			event_type,
            seating_type
		};

		return await HTTP.get('/v1/karen/filter-settings-data?' + buildQuery(params));
	};

    async getSelectableEventLocations(leadEventId) {
		let params = {
			leadEventId
		};

		return await HTTP.get('/v1/karen/selectable-locations?' + buildQuery(params));
    };

	async resetKarenFilterCriteria(leadEventId) {
		return await HTTP.put('/v1/karen/reset-filter-criteria/' + leadEventId);
	}

	async getInitialFilterData(leadId, eventId) {
		let params = {
			lead_id: leadId,
			event_id: eventId
		};

		return await HTTP.get('/v1/karen/initial-filter-settings?' + buildQuery(params));
	}
}();
