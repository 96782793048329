<template>
	<div>
		<modal :show.sync="showFilterModal" body-classes="p-0"
			modal-classes="modal-dialog-centered modal-xl show d-block"
		>
			<div class="modal-header">
				<h5 class="modal-title">Venue Search Filter</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					@click="showFilterModal = false"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row mb-4">
					<div class="col">
						<h3>Venue Type</h3>
						<div>
							<el-checkbox-group v-model="selectedVenueType">
								<el-checkbox v-for="(option, index) in venueTypeOptions"
									:key="`venue_type_${option.value}_${index}`"
									@change="handleCheckboxChange(option.value)"
									:label="option.value"
									class="event-type-checkbox"
								>{{ option.label }}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="col">
						<div class="row">
							<div class="col-8">
								<h3>Location</h3>
								<el-select v-model="selectedLocation" placeholder="Select" class="location-drop-down">
									<el-option v-for="(city, index) in selectableLocations" :key="`location_${city.name}_${index}`"
										:label="city.name"
										:value="city.id"
									></el-option>
								</el-select>
							</div>
							<div class="col-4">
								<h3>Radius</h3>
								<el-select v-model="selectedLocationRadius" placeholder="Select" class="location-radius-drop-down">
									<el-option v-for="(radius, index) in locationRadiuses" :key="`location_radius_${radius}_${index}`"
										:label="`${radius} km`"
										:value="radius"
									></el-option>
								</el-select>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-4 mb-4">
					<div class="col-6">
                        <div class="row">
                                <h3 class="col-3 mb-4">Budget</h3>
                                <div class="col-9">
                                    <el-radio v-model="budgetType" label="budgetTotalPrice">Total Price</el-radio>
                                    <el-radio v-model="budgetType" label="budgetPricePerHead">Price Per Head</el-radio>
                                </div>
                        </div>
						<div v-show="budgetType === 'budgetTotalPrice'">
							<HistogramSlider
                                ref="histogramSliderTotalBudget"
                                :key="budgetTotalPricesSliderKey"
								:width="520"
								:bar-height="100"
								:data="totalCosts"
								:prettify="budgetPriceFormat"
								:drag-interval="true"
								:force-edges="false"
								:colors="['#1171ef', '#1171ef']"
								:grid="true"
								:min="0"
								:max="100000"
                                :initial-from="selectedMinTotalBudget"
                                :initial-to="selectedMaxTotalBudget"
								:step="500"
								:hideMinMax="false"
								:hideFromTo="false"
								:updateColorOnChange="false"
								@finish="totalPriceSlideHandler"
							/>
						</div>
						<div v-show="budgetType === 'budgetPricePerHead'">
							<HistogramSlider
								ref="histogramSliderPerHeadBudget"
                                :key="budgetPricesPerHeadSliderKey"
								:width="520"
								:bar-height="100"
								:data="budgetPricesPerHeadData[eventType]"
                                :min="0"
								:max="500"
								:prettify="budgetPriceFormat"
								:drag-interval="true"
								:force-edges="false"
								:colors="['#1171ef', '#1171ef']"
								:grid="true"
                                :initial-from="selectedMinBudgetPerHead"
                                :initial-to="selectedMaxBudgetPerHead"
								:step="10"
								:hideMinMax="false"
								:hideFromTo="false"
								:updateColorOnChange="false"
                                @finish="budgetPerHeadSlideHandler"
                                />
						</div>
					</div>
                    <div class="col-6">
                        <h3 class="mb-4">Guests</h3>
                        <HistogramSlider
                            ref="histogramSliderVenueCapacities"
                            :key="capacitySliderKey"
                            :width="520"
                            :bar-height="100"
                            :data="venueCapacitiesData"
                            :drag-interval="true"
                            :force-edges="true"
                            :colors="['#1171ef', '#1171ef']"
                            :grid="true"
                            :fromFixed="true"
                            :initial-from="selectedMinGuestCount"
                            :initial-to="selectedMaxGuestCount"
                            :step="2"
                            :hideMinMax="false"
                            :hideFromTo="false"
                            :updateColorOnChange="false"
                            @finish="venueCapacitySliderHandler"
                        />
                    </div>
				</div>

				<div class="row mt-4 mb-4">
					<div class="col">
						<h3>Style</h3>
						<div>
							<el-checkbox-group v-model="selectedStyles">
								<el-checkbox v-for="(style, index) in venueStyles"
									:key="`venue_style_${style.value}_${index}`"
									:label="style.value"
									class="style-checkbox"
									border
								>{{ style.name }}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>
				<div class="row" v-if="activeSecondarySettings.length">
					<div class="col">
						<h2 >Addon Options</h2>
						<div class="row mt-4 ">
							<div class="col" v-for="(option, optionIndex) in activeSecondarySettings" :key="`option_${option.key}`">
								<div>
									<h4>{{ option.title }}</h4>
									<el-checkbox v-model="selectedSecondaryOptions[option.key]">
										<template v-if="option.key === 'late_license'">
											<el-time-picker
												v-model="lateLicenseEndTime"
												value-format="HH:mm"
												:picker-options="{
													selectableRange: ['00:00:00 - 02:00:00','21:00:00 - 23:59:00'],
													format: 'HH:mm'
												}"
												placeholder="Select time"
												:disabled="selectedSecondaryOptions['late_license'] == false"
											>
											</el-time-picker>
										</template>
										<template v-else>
											YES
										</template>
									</el-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer justify-content-between">
				<div class="col pl-0 text-left">
					<button type="button" class="btn btn-secondary reset-button" data-dismiss="modal"
						@click="resetFilter"
					>Reset</button>
				</div>
				<div v-if="formWasReset" class="text-sm text-warning">
					<b>Please click Apply to update search results and save this setting.</b>
				</div>
				<div class="col pr-0 text-right">
					<button type="button" class="btn btn-secondary cancel-button" @click="closeFilterModal">{{  formWasReset ? 'Cancel' : 'Close' }}</button>
					<button type="button" class="btn btn-primary submit-button" @click="submitFilters">Apply</button>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import '@/components/VcHistogramSlider/histogram-slider.css';
import HistogramSlider from '@/components/VcHistogramSlider/histogram-slider.umd.js';
import recommendationMixin from '@/helpers/recommendationMixin';
import KarenService from '@/services/karen.service';
import { Checkbox, CheckboxGroup, DatePicker, Option, Radio, Select, Slider, TimePicker } from "element-ui";

export default {
	props: {
		showFilter: {
			type: Boolean,
			default: false
		},
		searchCriteria: {
			type: Object,
			required: true
		},
		selectableLocations: {
			type: Array,
			default() { return []; }
		}
	},
	components: {
		Modal,
		[Checkbox.name]: Checkbox,
		[CheckboxGroup.name]: CheckboxGroup,
		[Option.name]: Option,
		[Select.name]: Select,
		[Radio.name]: Radio,
		[HistogramSlider.name]: HistogramSlider,
		[Slider.name]: Slider,
		[DatePicker.name]: DatePicker,
		[TimePicker.name]: TimePicker
	},
	data() {
		return {
			formWasReset: false,
			showFilterModal: false,
			venueTypeOptions: [
				{ label: 'Wedding', value: 'wedding' },
				{ label: 'Corporate', value: 'corporate' },
				{ label: 'Social', value: 'social' }
			],
			venueStyles: [],
			selectedVenueType: ["wedding"],
			selectedLocation: null,
			selectedLocationRadius: null,
			selectedStyles: [],
			budgetType: 'budgetPricePerHead',
            budgetPricesPerHeadData: {
				"default": [0],
				"wedding": [0],
				"corporate": [0],
				"social": [0]
			},
            selectedMinBudgetPerHead: 0,
			selectedMaxBudgetPerHead: 0,
            venueCapacitiesData: [],
            selectedMinGuestCount: 1,
            selectedMaxGuestCount: 100,
            unSyncedBudgetType: null,
			addonOptionsGroups: [
				[
					{ value: 'accommodation_on_site', title: 'Accommodation (On site?)' },
					{ value: 'ceremonies_onsite', title: 'Ceremony On Site' },
					{ value: 'mandap_ceremony', title: 'Mandap Ceremony' }
				],
				[
					{ value: 'external_catering_required', title: 'External Catering' },
					{ value: 'outdoor_space', title: 'Outdoor Space' },
					{ value: 'late_license_required', title: 'Late License' }
				]
			],
			selectedSecondaryOptions: {
				accommodation_on_site: false,
				ceremonies_onsite: false,
				mandap_ceremony: false,
				external_catering_required: false,
				outdoor_space: false,
				late_license: false
			},
			lateLicenseEndTime: '21:00',
			activeSecondarySettings: {},
			roomDrawer: false,
            capacitySliderRandomStr: 'abc',
            budgetPerHeadSliderRandomStr: 'mno',
            budgetTotalPricesRandomStr: 'bat'
		};
	},
	computed: {
        budgetPricesPerHeadSliderKey: {
            get() {
               return `budgetPricesPerHeadSlider_${this.budgetPerHeadSliderRandomStr}`;
            },
            set(changedKey) {
                this.budgetPerHeadSliderRandomStr = changedKey;

            }
        },
        budgetTotalPricesSliderKey: {
            get() {
               return `budgetTotalPricesSlider_${this.budgetTotalPricesRandomStr}`;
            },
            set(changedKey) {
                this.budgetTotalPricesRandomStr = changedKey;

            }
        },
        capacitySliderKey: {
            get() {
               return `capacitySlider_${this.capacitySliderRandomStr}`;
            },
            set(changedKey) {
                this.capacitySliderRandomStr = changedKey;

            }
        },
        selectedMinTotalBudget: {
            get() {
                return this.selectedMaxGuestCount * this.selectedMinBudgetPerHead;
            },
        },
        selectedMaxTotalBudget: function () {
            return this.selectedMaxGuestCount * this.selectedMaxBudgetPerHead;
        },
		currentCurrency() {
			let currencySymbol = this.$store.state.country.currency ? this.$store.state.country.currency : "$";
			return currencySymbol;
		},
		budgetPriceFormat() {
			return (value) => {
				return this.currentCurrency + value.toLocaleString();
			};
		},
		eventType() {
			return this.selectedVenueType[0] || 'default';
		},
		totalCosts() {
			return this.budgetPricesPerHeadData[this.eventType]?.map((pricePerHead) => pricePerHead * this.selectedMaxGuestCount);
		},
		locationRadiuses() {
			return [
				10,
				20,
				30,
				40,
				50,
				100,
				200
			];
		}
	},
	watch: {
		showFilter: function (newVal) {
			this.showFilterModal = newVal;
		},

        /* ##################################### CAREFUL WITH THE BELOW 4 WATCHERS #######################################
           ##################################################################################################################
           #                              Do not create circular watch reference between them.######                        #
           ##################################### CAREFUL WITH THE BELOW 4 WATCHERS #################################### */
        selectedMinBudgetPerHead: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.$refs.histogramSliderPerHeadBudget.update({ from: newVal, to: this.selectedMaxBudgetPerHead });

                this.$refs.histogramSliderTotalBudget.update(
                    { from: newVal * this.selectedMaxGuestCount, to: this.selectedMaxTotalBudget }
                );
            }
        },
        selectedMaxBudgetPerHead: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.$refs.histogramSliderPerHeadBudget.update({ from: this.selectedMinBudgetPerHead, to: newVal });

                this.$refs.histogramSliderTotalBudget.update(
                    { from: this.selectedMinTotalBudget , to: newVal * this.selectedMaxGuestCount }
                );
            }
        },
        selectedMinTotalBudget: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.$refs.histogramSliderTotalBudget.update({ from: newVal, to: this.selectedMaxTotalBudget });

                this.$refs.histogramSliderPerHeadBudget.update(
                    { from: newVal / this.selectedMaxGuestCount, to: this.selectedMaxBudgetPerHead }
                );
                this.budgetSyncingPending = true;
                this.unSyncedBudgetType = 'budgetPricePerHead';
            }
        },
        selectedMaxTotalBudget: function (newVal, oldVal) {
            if (newVal != oldVal && this.budgetType == 'budgetTotalPrice') {
                this.$refs.histogramSliderTotalBudget.update({ from: this.selectedMinTotalBudget, to: newVal });

                this.$refs.histogramSliderPerHeadBudget.update(
                    { from: this.selectedMinBudgetPerHead, to: newVal / this.selectedMaxGuestCount }
                );
                this.budgetSyncingPending = true;
                this.unSyncedBudgetType = 'budgetPricePerHead';
            }
        },

        /* ##################################### CAREFUL WITH THE ABOVE 4 WATCHERS #######################################
           ##################################################################################################################
           #                              Do not create circular watch reference between them.######                        #
           ##################################### CAREFUL WITH THE ABOVE 4 WATCHERS #################################### */
		showFilterModal: function (newVal) {
			if (! newVal) {
				this.$emit('update:showFilter', false);
			}
		},
		selectedVenueType: async function (newVal) {
			if (newVal?.length) {
				await this.loadFilterData();
			}
		},
        'searchCriteria.budget_per_person_min': function (newVal, oldVal) {
            if ([null, undefined].includes(newVal)) {
                newVal = 0;
            }
            this.selectedMinBudgetPerHead = newVal;
        },
        'searchCriteria.budget_per_person_max': function (newVal, oldVal) {
            if ([null, undefined].includes(newVal)) {
                newVal = 0;
            }
            this.selectedMaxBudgetPerHead = newVal;

        },
		'searchCriteria.accommodation_on_site': function (newVal, oldVal) {
			this.selectedSecondaryOptions['accommodation_on_site'] = newVal;
		},
		'searchCriteria.ceremonies_onsite': function (newVal, oldVal) {
			this.selectedSecondaryOptions['ceremonies_onsite'] = newVal;
		},
		'searchCriteria.external_catering_required': function (newVal, oldVal) {
			this.selectedSecondaryOptions['external_catering_required'] = newVal;
		},
		'searchCriteria.mandap_ceremony': function (newVal, oldVal) {
			this.selectedSecondaryOptions['mandap_ceremony'] = newVal;
		},
		'searchCriteria.outdoor_space': function (newVal, oldVal) {
			this.selectedSecondaryOptions['outdoor_space'] = newVal;
		},
		'searchCriteria.late_license_end_time': function (newVal, oldVal) {
			this.selectedSecondaryOptions['late_license'] = !!newVal;
			if (newVal && newVal.length) {
				this.lateLicenseEndTime = newVal;
			}
		},
		'searchCriteria.guest_count': function(newVal, oldVal) {
			if (newVal) {
				this.selectedMaxGuestCount = newVal;
			}
		},
		'searchCriteria.event_style': function(newVal, oldVal) {
			if (newVal) {
				this.selectedStyles = newVal;
			}
		},
		'searchCriteria.event_type': function(newVal, oldVal) {
			if (newVal) {
				this.selectedVenueType = [newVal];
			}
		},
		'searchCriteria.city': function(newVal, oldVal) {
			if (newVal) {
				let city = this.$props.selectableLocations.filter((location) => location.name == newVal);
				if (city.length) {
					this.selectedLocation = city[0].id;
				}
			}
		},
		'searchCriteria.radius': function(newVal, oldVal) {
			if (newVal) {
				this.selectedLocationRadius = newVal;
			}
		},
		'selectedLocation': function(newVal, oldVal) {
			if (newVal) {
				let city = this.$props.selectableLocations.filter((location) => location.name == newVal);
				if (city.length) {
					this.selectedLocation = city[0].id;
				}
			}
		},
	},
	mixins: [recommendationMixin],
	methods: {
        syncTotalAndPerHeadBudget() {
            if (! this.budgetSyncingPending) {
                return;
            }
        },
		totalPriceSlideHandler(sliderPayload) {
            this.selectedMinBudgetPerHead = this.selectedMaxGuestCount == 0 ? 1 : sliderPayload.from / this.selectedMaxGuestCount;
			this.selectedMaxBudgetPerHead = this.selectedMaxGuestCount == 0 ? 10 : sliderPayload.to / this.selectedMaxGuestCount;
		},
		budgetPerHeadSlideHandler(sliderPayload) {
			this.selectedMinBudgetPerHead = sliderPayload.from;
			this.selectedMaxBudgetPerHead = sliderPayload.to;
		},
        venueCapacitySliderHandler(sliderPayload) {
			this.selectedMinGuestCount = sliderPayload.from;
			this.selectedMaxGuestCount = sliderPayload.to;
        },
		handleCheckboxChange(value) {
			this.selectedVenueType = [value];
		},
		closeFilterModal() {
            if (this.formWasReset) {
                this.$emit('selectCurrentFilter');
            }
			this.showFilterModal = false;
			this.formWasReset = false;
		},
		async loadFilterData() {
			let response = await KarenService.loadKarenFilterData(
				this.$props.searchCriteria.country,
				this.selectedVenueType[0],
				this.$props.searchCriteria.seating_type
			);

			if (response?.data?.activeSecondaryKarenSettings) {
				this.activeSecondarySettings = response.data.activeSecondaryKarenSettings;
			}

			if (response?.data?.venueStyles) {
				this.venueStyles = response.data.venueStyles;
			}

			if (response?.data?.weddingVenueFeesPerHead) {
				this.budgetPricesPerHeadData['wedding'] = response.data.weddingVenueFeesPerHead;
			}

			if (response?.data?.corporateVenueFeesPerHead) {
				this.budgetPricesPerHeadData['corporate'] = response.data.corporateVenueFeesPerHead;
			}

			if (response?.data?.socialVenueFeesPerHead) {
				this.budgetPricesPerHeadData['social'] = response.data.socialVenueFeesPerHead;
			}

            if (response?.data?.venueCapacities) {
				this.venueCapacitiesData = response.data.venueCapacities;
			}

            // This is the hack to force the histogram to re-render - change the bound keys.
            this.capacitySliderKey = this.capacitySliderRandomStr.split("").reverse().join("");
            this.budgetPricesPerHeadSliderKey = this.budgetPerHeadSliderRandomStr.split("").reverse().join("");
            this.budgetTotalPricesSliderKey = this.budgetTotalPricesRandomStr.split("").reverse().join("");

            this.$nextTick(() => {
                this.capacitySliderKey = this.capacitySliderRandomStr.split("").reverse().join("");
                this.budgetPricesPerHeadSliderKey = this.budgetPerHeadSliderRandomStr.split("").reverse().join("");
                this.budgetTotalPricesSliderKey = this.budgetTotalPricesRandomStr.split("").reverse().join("");
            });
		},

		async resetFilter() {
			this.formWasReset = true;
			this.$emit('resetSearchFilter');
		},

		async submitFilters() {
            this.syncTotalAndPerHeadBudget();
			let params = {
				event_type: this.selectedVenueType[0],
				location: this.selectedLocation,
				radius: this.selectedLocationRadius,
				category: this.selectedStyles,
				guests: this.selectedMaxGuestCount,
				accommodation_required: this.selectedSecondaryOptions['accommodation_on_site']
					? 'Yes' : 'No',
				external_catering_required: this.selectedSecondaryOptions['external_catering_required']
					? 'Yes' : 'No',
				ceremonies_onsite: this.selectedSecondaryOptions['ceremonies_onsite']
					? 'Yes' : 'No',
				mandap_ceremony: this.selectedSecondaryOptions['mandap_ceremony']
					? 'Yes' : 'No',
				outdoor_space_required: this.selectedSecondaryOptions['outdoor_space']
					? 'Yes' : 'No'
			};

			if (this.budgetType === 'budgetTotalPrice') {
				params.price_per_day_min = this.selectedMinTotalBudget;
				params.price_per_day_max = this.selectedMaxTotalBudget;

			} else {
				params.price_per_person_min = this.selectedMinBudgetPerHead;
				params.price_per_person_max = this.selectedMaxBudgetPerHead;
			}

			if (this.selectedSecondaryOptions.late_license) {
				params.late_license_end_time = this.lateLicenseEndTime;
			}

			let results = await this.searchVenues(
				this.$route.params.id,
				this.$route.params.eventId,
				params
			);

            if (results.data) {
                this.updateKarenListingPageData(results.data);
            }

			this.formWasReset = false;
			this.showFilterModal = false;
		}
	}
};
</script>

<style lang="scss">
@mixin form-label {
	color: #000;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.modal {
	.modal-content {
		border-radius: 20px;

		.modal-body {
			&:first-of-type {
				max-height: 100vh;
				overflow-y: auto;
			}

			.modal-header {
				border-bottom: 1px solid #DCDFE6;

				.close>span {
					font-size: 38px;
				}
			}

			.modal-footer {
				border-top: 1px solid #DCDFE6;
			}

			h2 {
				color: #8B98A9;
			}

			.el-select {
				width: 100%;

				.el-input input {
					@include form-label;
				}
			}

			.el-radio {
				.el-radio__label {
					@include form-label;
				}
			}

			.el-checkbox.is-bordered {
				margin: 5px;
				color: #CECECE;
				border-color: #CECECE;
				border-radius: 20px;

				&:hover {
					border-color: #1171EF;
				}

				&.is-checked {
					background-color: #1171EF;
					border-color: #1171EF;

					.el-checkbox__label {
						color: #fff;
					}
				}

				.el-checkbox__input {
					display: none;
				}
			}

			.el-checkbox {
				&:hover {
					.el-checkbox__input {
						&:not(.is-disabled) {
							.el-checkbox__inner {
								border-color: #1171EF;
							}
						}
					}
				}

				.el-checkbox__label {
					@include form-label;
				}

				.el-checkbox__input {
					&.is-disabled .el-checkbox__inner:hover {
						border-color: #DCDFE6 !important;
					}

					.el-checkbox__inner {
						border-radius: 0px;
						width: 26px;
						height: 26px;

						&:after {
							top: 2px;
							left: 8px;
							width: 5px;
							height: 13px;
							border-width: 3px;
						}
					}
				}
			}

			.el-slider {
				.el-slider__runway {
					border-radius: 50px;
				}
			}

			.vue-histogram-slider-wrapper {
				max-width: 100%;
				margin: auto 0;
				
				.irs-handle {
					top: 31px;
					width: 16px;
					height: 16px;
					border: 2px solid #409EFF;
					background-color: #FFF;
					border-radius: 50%;
					box-shadow: none;
					transition: ease-in-out 0.25s transform;
					&:hover {
						transform: scale(1.2);
					}
				}

				.irs-grid-pol {
					display: none;
				}

				.irs-grid-text {
					display: none;
				}

				.irs--round {
                    .irs-min, .irs-max {
                        top: 50px;
                    }
					.irs-from,
					.irs-to {
						top: 50px;
						left: 2px;
						color: #909399;
						font-size: 14px;
						background-color: transparent;

						&:after,
						&:before {
							display: none
						}
					}
				}
			}
		}
	}
}
</style>
