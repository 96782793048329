<template>
	<el-drawer
		direction="rtl"
        :visible.sync="drawerVisible"
		:before-close="handleClose"
		:title="`${venueRoomName} - At a glance`">
		<div class="container venue-room-details">
			<div class="checklist" v-if="venueChecklist.length">
                <div class="font-weight-bold text-dark">Checklist</div>
                <ul class="mt-2">
                    <li v-for="(item, ind) in venueChecklist" class="list-item mb-1">
						<i class="fal fa-check-circle fa-list-item"></i>
						<span class="pl-3 inline-block">{{ item }}</span>
					</li>
                </ul>
            </div>

        <div class="font-weight-bold text-dark">Gallery</div>
        <CoolLightBox
            :items="largeImages"
            :index="index"
            @close="closeLightbox">
        </CoolLightBox>
        <div class="row mt-2 mr-1 venue-room-gallery">
            <div class="col-4 py-1"
                v-for="(image, imageIndex) in thumbnails"
                :class="{ 'px-1': imageIndex % 3 == 1, 'px-0': imageIndex % 3 != 1 }"
                :key="imageIndex">
                <img
                    class="img gallery-thumb w-100"
                    @click="index = imageIndex"
                    :src="image"
                />
            </div>
			</div>
			<div class="close-area">
				<div class="row">
					<div class="col text-right">
						<el-button @click="handleClose">Close</el-button>
					</div>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { Button, Drawer } from 'element-ui';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
	components: {
		[Drawer.name]: Drawer,
		[Button.name]: Button,
		CoolLightBox
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		venueRoomName: {
			type: String,
			default: ''
		},
		venueRoomDescription: {
			type: String,
			default: ''
		},
		venueRoomImages: {
			type: Array,
			default: () => []
		},
        venueChecklist: {
            type: Array
        }
	},
	data() {
		return {
			index: null
		};
	},
	computed: {
        drawerVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            }
        },
		thumbnails() {
			return this.venueRoomImages.map(image => image.large);
		},
		largeImages() {
			return this.venueRoomImages.map(image => image.large);
		}
	},
	methods: {
		handleClose() {
			if (this.index === null) {
				this.$emit('update:visible', false);
			}
		},
		closeLightbox() {
			this.index = null;
		},
		handleKeydown(event) {
			if (event.key === 'Escape' && this.index !== null) {
				this.closeLightbox();
				event.stopPropagation();
			}
		}
	},
	mounted() {
		window.addEventListener('keydown', this.handleKeydown);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleKeydown);
	}
};
</script>


<style lang="scss">
.el-drawer__header {
	span[role="heading"] {
		color: #000000;
		font-weight: 600;
        outline: 0;
	}
}

.venue-room-details {
    padding: 0px 20px;

    .checklist {

        ul {
            padding-inline-start: 0;
		}

		li{
			color: rgba(0, 0, 0, 0.6);
			list-style: none;
		}

		.fa-list-item {
			display: inline-block;
			width: 1.5em;
			text-align: center;
		}
		.list-item {
			display: flex;
			align-items: baseline;
		}
		.list-item span {
			flex: 1;
		}

		i.fa-list-item {
			color: lightseagreen;
		}

    }
	.venue-room-gallery {
        padding-left: 20px;
		.gallery-thumb {
			cursor: pointer;
			height: 110px;
			object-fit: cover;
			transition: opacity 0.25s ease-in-out;

			&:hover {
				opacity: 0.75;
			}
		}
	}
}

.close-area {
	position: fixed;
	bottom: 15px;
	right: 15px;
}
</style>
